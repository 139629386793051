import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import AdminHome from "./admin/AdminHome";
import AddChampionship from "./admin/championship/add-championship/AddChampionship";
import CreateScoringVariant from "./admin/championship/CreateScoringVariant";
import RemoveChampionship from "./admin/championship/RemoveChampionship";
import Config from "./admin/Config";
import AddAssignment from "./admin/drivers/AddAssignment";
import DriverPhoto from "./admin/drivers/DriverPhoto";
import RemoveAssignment from "./admin/drivers/RemoveAssignment";
import UpdateDriver from "./admin/drivers/UpdateDriver";
import CreateCircuit from "./admin/general/CreateCircuit";
import CreateFlag from "./admin/general/CreateFlag";
import CreateGame from "./admin/general/CreateGame";
import UpdateRegulation from "./admin/general/UpdateRegulation";
import UpdateGP from "./admin/grandsprix/UpdateGP";
import UploadGP from "./admin/grandsprix/UploadGP";
import Downloads from "./championship/Downloads";
import Drivers from "./championship/Drivers";
import RaceEntries from "./championship/RaceEntries";
import Standings from "./championship/Standings";
import TitularDrivers from "./admin/drivers/TitularDrivers";
import Driver from "./driver/Driver";
import DriversLicenses from "./DriversLicenses";
import ErrorPage from "./ErrorPage";
import FastestLaps from "./grand-prix/FastestLaps";
import Findings from "./grand-prix/Findings";
import GrandPrix from "./grand-prix/GrandPrix";
import GrandPrixLineChart from "./grand-prix/GrandPrixLineChart";
import Incidents from "./grand-prix/Incidents";
import Leaders from "./grand-prix/Leaders";
import PostRace from "./grand-prix/PostRace";
import RaceLivetiming from "./grand-prix/RaceLivetiming";
import Qualifying from "./grand-prix/Qualifying";
import Race from "./grand-prix/Race";
import DidYouKnow from "./historic/DidYouKnow";
import Nationalities from "./historic/Nationalities";
import Races from "./historic/Races";
import Seasons from "./historic/Seasons";
import Stats from "./historic/Stats";
import Home from "./home/Home";
import LiveTiming from "./livetiming/LiveTiming";
import Regulation from "./Regulation";
import Season from "./Season";
import Team from "./Team";
import UpdateProfile from "./user/UpdateProfile";
import UpdateTeam from "./admin/team/UpdateTeam";
import CreateTeam from "./admin/team/CreateTeam";
import PostPenaltiesGP from "./admin/grandsprix/PostPenaltiesGP";
import UpdateGPResults from "./admin/grandsprix/UpdateGPResults";
import TeamCorrelations from "./admin/team/TeamCorrelations";
import { default as DownloadsAdmin } from "./admin/championship/Downloads";
import { useUser } from "../components/UserContext";
import Maintenance from "./Maintenance";
import axios from "axios";
import Transfers from "./championship/Transfers";
import SkipShift from "./admin/championship/SkipShift";
import useIsBanned from "../hooks/useIsBanned";
import Banned from "./Banned";
import { i18n } from "../App";
import ChangeDriverTeam from "./admin/team/ChangeDriverTeam";
import ChangeLivetimingUsername from "./admin/drivers/ChangeLivetimingUsername";
import CreateCustomScoringName from "./admin/championship/CreateCustomScoringName";
import DQDriver from "./admin/championship/DQDriver";
import CreateNew from "./admin/news/CreateNew";
import UpdateNews from "./admin/news/UpdateNews";
import UpdateIndexPhotos from "./admin/general/UpdateIndexPhotos";
import ChampionshipIndex from "../components/ChampionshipIndex";
import LoadingContent from "../components/LoadingContent";
import New from "../components/News/New";
import UpdateCircuit from "./admin/general/UpdateCircuit";
import GenerateXMLHCFile from "./admin/grandsprix/GenerateXMLHCFile";
import AddTeamChampionship from "./admin/team/AddTeamChampionship";
import DeleteTeamChampionship from "./admin/team/DeleteTeamChampionship";
import CreateClass from "./admin/team/CreateClass";
import Test from "./Test";
import GenerateACXMLHCFile from "./admin/grandsprix/GenerateACXMLHCFile";

const AppContent = ({
  setUpdateNotifications,
}: {
  setUpdateNotifications: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { user, setUser } = useUser() as any;
  const [maintenance, setMaintenance] = useState(false);
  const [error, setError] = useState(false);
  const { banned } = useIsBanned();
  const [mainChampionship, setMainChampionship] = useState({
    name: "",
    titular_drivers: -1,
  });
  const [secChampionship, setSecChampionship] = useState({
    name: "",
    titular_drivers: -1,
  });
  const [event, setEvent] = useState({
    name: "",
    titular_drivers: -1,
  });

  useEffect(() => {
    let token = window.localStorage.getItem("userLogged");
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/main/maintenance`,
        {
          user,
          token: token == null ? null : JSON.parse(token).token,
        }
      )
      .then(({ data }) => {
        setMaintenance(data);
      })
      .catch(({ response }) => {
        console.log(response);
        setError(true);
      });
  }, [user, i18n.language]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/getCurrentChampionships`
      )
      .then(({ data }) => {
        setMainChampionship(data.main_champ);
        setSecChampionship(data.second_champ);
        setEvent(data.event);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  if (mainChampionship.name == "" || secChampionship.name == "")
    return <LoadingContent />;
  if (banned) return <Banned />;
  if (error) return <ErrorPage />;
  if (maintenance) return <Maintenance />;
  return (
    <Routes>
      <Route path={t("routes:Home")} element={<Home />} />
      <Route path={t("routes:EditProfile")} element={<UpdateProfile />} />
      <Route path={t("routes:Driver") + "/:username"} element={<Driver />} />
      <Route path={t("routes:Team") + "/:team"} element={<Team />} />
      <Route path={t("routes:DriversLicenses")} element={<DriversLicenses />} />
      {/* CHAMPIONSHIP */}
      <Route path={t("routes:Drivers")} element={<Drivers />} />
      <Route path={t("routes:Standings")} element={<Standings />} />
      <Route
        path={t("routes:Transfers")}
        element={<Transfers setUpdateNotification={setUpdateNotifications} />}
      />
      <Route path={`${t("routes:Regulation")}`} element={<Regulation />} />
      <Route
        path={t("routes:RaceEntries")}
        element={
          <RaceEntries setUpdateNotifications={setUpdateNotifications} />
        }
      />
      <Route path={`${t("routes:Livetiming")}`} element={<LiveTiming />} />
      {/* SECONDARYCHAMP */}
      <Route
        path={`${t("routes:Livetiming")}/:champ`}
        element={<LiveTiming />}
      />
      <Route
        path={`${t("routes:Regulation")}/:champ`}
        element={<Regulation />}
      />
      <Route path={`${t("routes:Drivers")}/:champ`} element={<Drivers />} />
      <Route
        path={`${t("routes:Transfers")}/:champ`}
        element={
          <Transfers
            setUpdateNotification={setUpdateNotifications}
            isSecondChamp={true}
          />
        }
      />
      <Route
        path={`${t("routes:RaceEntries")}/:champ`}
        element={
          <RaceEntries setUpdateNotifications={setUpdateNotifications} />
        }
      />
      <Route
        path={`${t("routes:Home")}/:champ`}
        element={<ChampionshipIndex />}
      />
      {/* GRAND PRIX */}
      <Route
        path={`${t("routes:Findings")}/:grandprix`}
        element={<Findings />}
      />
      <Route
        path={`${t("routes:Incidents")}/:grandprix`}
        element={<Incidents />}
      />
      <Route
        path={`${t("routes:PostRace")}/:grandprix`}
        element={<PostRace />}
      />
      <Route
        path={`${t("routes:RaceLivetiming")}/:grandprix`}
        element={<RaceLivetiming />}
      />
      <Route
        path={`${t("routes:GapChart")}/:grandprix`}
        element={<GrandPrixLineChart type_chart="GAPS" />}
      />
      <Route
        path={`${t("routes:PositionChart")}/:grandprix`}
        element={<GrandPrixLineChart type_chart="POSITIONS" />}
      />
      <Route path={`${t("routes:Leaders")}/:grandprix`} element={<Leaders />} />
      <Route
        path={`${t("routes:FastestLaps")}/:grandprix`}
        element={<FastestLaps />}
      />
      <Route path={`${t("routes:Race")}/:grandprix`} element={<Race />} />
      <Route
        path={`${t("routes:Qualifying")}/:grandprix`}
        element={<Qualifying />}
      />
      <Route
        path={t("routes:GrandPrix") + "/:grandprix"}
        element={<GrandPrix />}
      />
      {/* HISTORIC */}
      <Route path={`${t("routes:Season")}/:season`} element={<Season />} />
      <Route path={t("routes:Stats")} element={<Stats />} />
      <Route path={t("routes:Seasons")} element={<Seasons />} />
      <Route path={t("routes:Nationalities")} element={<Nationalities />} />
      <Route path={t("routes:HistoricRaces")} element={<Races />} />
      <Route path={t("routes:DidYouKnow")} element={<DidYouKnow />} />
      {/* ADMIN */}
      <Route path={"/Admin"} element={<AdminHome />} />
      <Route path={"/Admin/Config"} element={<Config />} />
      <Route path={"/Admin/SkipShift"} element={<SkipShift />} />
      <Route path={"/Admin/CreateFlag"} element={<CreateFlag />} />
      <Route path={"/Admin/CreateGame"} element={<CreateGame />} />
      <Route path={"/Admin/CreateCircuit"} element={<CreateCircuit />} />
      <Route path={"/Admin/UpdateCircuit"} element={<UpdateCircuit />} />
      <Route path={"/Admin/UpdateRegulation"} element={<UpdateRegulation />} />
      <Route path={"/Admin/UploadGP"} element={<UploadGP />} />
      <Route path={"/Admin/UpdateGP"} element={<UpdateGP />} />
      <Route path={"/Admin/UpdateDriver"} element={<UpdateDriver />} />
      <Route path={"/Admin/AddAssignment"} element={<AddAssignment />} />
      <Route path={"/Admin/RemoveAssignment"} element={<RemoveAssignment />} />
      <Route path={"/Admin/DriverPhoto"} element={<DriverPhoto />} />
      <Route path={"/Admin/AddChampionship"} element={<AddChampionship />} />
      <Route
        path={"/Admin/RemoveChampionship"}
        element={<RemoveChampionship />}
      />
      <Route
        path={"/Admin/CreateScoringVariant"}
        element={<CreateScoringVariant />}
      />
      <Route
        path={"/Admin/CreateCustomScoringName"}
        element={<CreateCustomScoringName />}
      />
      <Route path={"/Admin/DQDriver"} element={<DQDriver />} />
      <Route
        path={"/Admin/UpdateIndexPhotos"}
        element={<UpdateIndexPhotos />}
      />
      <Route path={"/Admin/TitularDrivers"} element={<TitularDrivers />} />
      <Route path={"/Admin/UpdateGPResults"} element={<UpdateGPResults />} />
      <Route path={"/Admin/PostPenaltiesGP"} element={<PostPenaltiesGP />} />
      <Route path={"/Admin/Downloads"} element={<DownloadsAdmin />} />
      <Route path={"/Admin/UpdateTeam"} element={<UpdateTeam />} />
      <Route path={"/Admin/CreateTeam"} element={<CreateTeam />} />
      <Route path={"/Admin/TeamCorrelations"} element={<TeamCorrelations />} />
      <Route path={"/Admin/ChangeDriverTeam"} element={<ChangeDriverTeam />} />
      <Route path={"/Admin/CreateNew"} element={<CreateNew />} />
      <Route path={"/Admin/UpdateNews"} element={<UpdateNews />} />
      <Route
        path={"/Admin/AddTeamChampionship"}
        element={<AddTeamChampionship />}
      />
      <Route
        path={"/Admin/DeleteTeamChampionship"}
        element={<DeleteTeamChampionship />}
      />
      <Route path={"/Admin/CreateClass"} element={<CreateClass />} />
      <Route
        path={"/Admin/LivetimingRaceHC"}
        element={<LiveTiming server={3} />}
      />
      <Route
        path={"/Admin/GenerateXMLHCFile"}
        element={<GenerateXMLHCFile />}
      />
      <Route
        path={"/Admin/GenerateACXMLHCFile"}
        element={<GenerateACXMLHCFile />}
      />
      <Route
        path={"/Admin/ChangeLivetimingUsername"}
        element={<ChangeLivetimingUsername />}
      />
      <Route path="/*" element={<ErrorPage />} />
      <Route path={`${t("routes:News")}/:champ/:id`} element={<New />} />
      <Route path={`/test`} element={<Test />} />
    </Routes>
  );
};

export default AppContent;
