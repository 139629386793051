import "../../assets/style/grandprix_tables.css";
import React, { useEffect, useState } from "react";
import GrandPrixNavbar from "../../components/GrandPrix/GrandPrixNavbar";
import GrandPrixDriver from "../../components/GrandPrix/GrandPrixDriver";
import GrandPrixTeam from "../../components/GrandPrix/GrandPrixTeam";
import GrandPrixPosition from "../../components/GrandPrix/GrandPrixPosition";
import GrandPrixSectors from "../../components/GrandPrix/GrandPrixSectors";
import GrandPrixSessionTitle from "../../components/GrandPrix/GrandPrixSessionTitle";
import { useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ResultsHeader from "./ResultsHeader";
import LoadingContent from "../../components/LoadingContent";
import useSeasonStyles from "../../hooks/useSeasonStyles";
import {
  ClassFilter,
  getClassArray,
  getClassArraySimple,
} from "../../components/GrandPrix/ClassFilter";

const FastestLaps = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [typeRaces, setTypeRaces] = useState();
  const [hasTeams, setHasTeams] = useState();
  const [classFilter, setClassFilter] = useState<string | null>(null);
  let { grandprix } = useParams();
  const style = useSeasonStyles(grandprix);
  const [fastestLaps, setFastestLaps] = useState<{
    [key: string]: string | number;
  }>({});

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getFastestLaps`,
        {
          params: {
            name: grandprix,
          },
        }
      )
      .then(({ data }) => {
        setFastestLaps(data.fastestLaps);
        setTypeRaces(data.type_races);
        setHasTeams(data.has_teams);
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        throw new Error(response);
      });
  }, []);

  if (loading) return <LoadingContent />;
  else if (grandprix == null || Object.keys(fastestLaps).length === 0)
    return <ErrorPage />; //just in case gp name does not exist
  return (
    <div className="full-height padding-md app-container page-transition">
      <GrandPrixNavbar name={grandprix} active={4} />
      {Object.entries(fastestLaps).map((item, index) => {
        const session = item[0];
        const results: any = item[1];
        let testDriver: any = null;
        let testLap: any = null;
        let classArray = getClassArraySimple(results);
        try {
          testDriver = results[0];
          testLap = results[0].laps[0];
        } catch (e) {}
        return (
          <div key={index} className={`session fl-session ${style}`}>
            <div className="session-content">
              <div className="session-title text-center">
                <GrandPrixSessionTitle
                  session={session}
                  race_length={Object.entries(fastestLaps).length}
                  suffix={t("HotLaps")}
                />
                {classArray.length > 1 && (
                  <ClassFilter
                    classes={classArray}
                    classFilter={classFilter}
                    setClassFilter={setClassFilter}
                    race={fastestLaps}
                    setRace={setFastestLaps}
                  />
                )}
              </div>
              <ResultsHeader
                testDriver={testDriver}
                page="FastestLaps"
                typeRaces={typeRaces}
                hasTeams={hasTeams}
              />
              <div className="session-drivers">
                {results.map((driver: any, index: number) => {
                  if (classFilter != null && driver.class != classFilter)
                    return <></>;
                  return (
                    <div key={index} className="gp-driver-laps-container">
                      <div
                        className={`gp-driver-row flex ${
                          driver.team === "LigaVirtualF1 Team"
                            ? "lvf1-driver"
                            : ""
                        }`}
                      >
                        <GrandPrixPosition driver={driver} session={session} />
                        <GrandPrixDriver driver={driver} />
                        {hasTeams === 1 && <GrandPrixTeam driver={driver} />}
                        {driver.class !== null &&
                          driver.class_color != null && (
                            <div className="gp-sector text-center">
                              <label
                                className="class_content"
                                style={{ background: driver.class_color }}
                              >
                                {driver.class.toUpperCase()}
                              </label>
                            </div>
                          )}
                        <div className="gp-hotlap text-center">
                          <label>{driver.fastestLap.$t}</label>
                        </div>
                        <div className="gp-gap text-center">
                          <label>{driver.gap}</label>
                        </div>
                        <GrandPrixSectors sectors={driver.fastestLap.sectors} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FastestLaps;
