import "../../assets/style/race.css";
import { useEffect, useState } from "react";
import GrandPrixSessionTitle from "../../components/GrandPrix/GrandPrixSessionTitle";
import ErrorPage from "../../views/ErrorPage";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ResultsHeader from "../../views/grand-prix/ResultsHeader";
import RaceRow from "../../views/grand-prix/race/RaceRow";
import LoadingContent from "../../components/LoadingContent";
import useSeasonStyles from "../../hooks/useSeasonStyles";
import { ClassFilter, getClassArray } from "./ClassFilter";

const GrandPrixRace = ({ grand_prix_name }: { grand_prix_name: string }) => {
  const { t } = useTranslation();
  const [activeDriver, setActiveDriver] = useState({});
  const [loading, setLoading] = useState(true);
  const [typeRaces, setTypeRaces] = useState();
  const [hasTeams, setHasTeams] = useState();
  const [classFilter, setClassFilter] = useState<string | null>(null);
  const [laps, setLaps] = useState();
  const style = useSeasonStyles(`${grand_prix_name}`);
  const [race, setRace] = useState<{
    [key: string]: string | number;
  }>({});

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/grandprix/getRace`,
        {
          params: {
            name: grand_prix_name,
          },
        }
      )
      .then(({ data }) => {
        setRace(data.raceResults);
        setTypeRaces(data.type_races);
        setHasTeams(data.has_teams);
        setLaps(data.laps);
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        throw new Error(response);
      });
  }, [grand_prix_name]);

  if (loading) return <LoadingContent />;
  else if (grand_prix_name == null || Object.keys(race).length === 0)
    return <ErrorPage />; //just in case gp name does not exist
  return (
    <>
      {Object.entries(race).map((item, index) => {
        let session = item[0];
        const results: any = item[1];
        let testDriver: any = null;
        let testLap: any = null;
        let classArray = getClassArray(results);
        try {
          testDriver = results[0][0];
          testLap = results[0][0].laps[0];
        } catch (e) {}
        return (
          <div key={index} className={`session rq-session ${style}`}>
            <div className="session-content">
              <div className="session-title text-center">
                <GrandPrixSessionTitle
                  session={session}
                  race_length={Object.entries(race).length}
                />
                {classArray.length > 1 && (
                  <ClassFilter
                    classes={classArray}
                    classFilter={classFilter}
                    setClassFilter={setClassFilter}
                    race={race}
                    setRace={setRace}
                    grand_prix_name={grand_prix_name}
                  />
                )}
              </div>
              <ResultsHeader
                testDriver={testDriver}
                page="Race"
                typeRaces={typeRaces}
                hasTeams={hasTeams}
                laps={laps}
              />
              <div className="session-drivers">
                {results.map((item: any, index: number) => {
                  if (classFilter != null && item[0].class != classFilter)
                    return <></>;
                  return (
                    <RaceRow
                      key={index}
                      driver={item}
                      testLap={testLap}
                      testDriver={testDriver}
                      activeDriver={activeDriver}
                      setActiveDriver={setActiveDriver}
                      session={session}
                      index={index}
                      typeRaces={typeRaces}
                      hasTeams={hasTeams}
                      laps={laps}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default GrandPrixRace;
