import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "../../assets/style/livetiming.css";
import FastestLaps from "./parts/FastestLaps";
import ErrorPage from "../ErrorPage";
import LoadingContent from "../../components/LoadingContent";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useUser } from "../../components/UserContext";
import Resizables from "./parts/Resizables";
import { io } from "socket.io-client";
import { getSessionData } from "../../utils/Livetiming";
import useIsAdmin from "../../hooks/useIsAdmin";
import { useParams } from "react-router-dom";

const LiveTiming = ({ server }: { server?: number }) => {
  document.title = "LVF1 - Live Timing";
  const { t } = useTranslation();
  let { champ } = useParams();
  const { access } = useIsAdmin();
  const [error, setError] = useState(false);
  const [nextGP, setNextGP] = useState<{
    circuit_id: number;
    date: Date;
    gp_race_event_id: number;
    type_championship: number;
    type_races: number;
    game_id: number;
  } | null>();
  const [serverData, setServerData] = useState<ServerData | null>();
  const [circleDrivers, setCircleDrivers] = useState<CircleDriver[]>([]);
  const [serverType, setServerType] = useState(0);
  const [serverId, setServerId] = useState(0);
  const [update, setUpdate] = useState(false);
  const [render, setRender] = useState(false);
  const [windowWidth, windowHeight] = useWindowSize();
  const debounceRefWindow = useRef<NodeJS.Timeout>();

  useEffect(() => {
    //Case admin panel for the private secondary server
    if (server != null) {
      setServerType(server);
      setServerId(server);
    } else {
      axios
        .get(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/championship/getTypeChamp`,
          {
            params: {
              champ,
            },
          }
        )
        .then(({ data }) => {
          setServerType(data.type);
          const server_id = data.type === 3 ? 2 : data.type;
          setServerId(server_id);
        })
        .catch(({ response }) => {
          throw new Error(response);
        });
    }
  }, [server, champ]);

  useEffect(() => {
    // Conectar al servidor de Socket.io
    const newSocket = io(
      `${
        process.env.NODE_ENV === "development"
          ? "http://localhost:5500"
          : "https://ligavirtualf1.es:5500"
      }`
    );

    // Escuchar el evento 'dataUpdate' para recibir los datos actualizados
    newSocket.on(`dataUpdate`, (data_general: any) => {
      if (data_general == null) return;
      const data: any = getSessionData(data_general, serverId);
      data.time_107 = data_general.time_107;
      if (data == null) return;
      setServerData(data);
      setCircleDrivers(data.server_live);
    });

    // Cleaning: disconnect the socket when the component is disassembled.
    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, [serverId]);

  useEffect(() => {
    setUpdate(!update);
  }, [render, windowWidth, windowHeight]);

  useEffect(() => {
    setServerData(null);
    setNextGP(null);
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/main/nextgp`,
        {
          params: { server: serverType },
        }
      )
      .then(({ data }) => {
        setNextGP(data.grand_prix);
      })
      .catch(({ response }) => {
        //setError(true);
        //throw new Error(response);
      });
  }, [serverType]);

  useEffect(() => {
    if (debounceRefWindow.current) clearTimeout(debounceRefWindow.current);
    debounceRefWindow.current = setTimeout(() => {
      setRender(!render);
    }, 1000);
  }, [window.innerWidth]);
  if (error) return <ErrorPage />;
  if (!access && server == 3) return <ErrorPage />;
  if (serverData == null || nextGP == null) return <LoadingContent />;
  return (
    <div className="full-height padding-lg app-container page-transition">
      <Resizables
        gp={nextGP}
        circleDrivers={circleDrivers}
        serverTiming={serverData.server_timing}
      />
      {server !== 3 && (
        <div id="fastest-lap-container">
          <h3 className="text-center">{t("HotLaps")}</h3>
          <FastestLaps
            fastest_laps={serverData.fastest_laps}
            time_107={serverData.time_107}
            server_id={serverData.server_timing.trackdata_id}
            typeRaces={nextGP.type_races}
          />
        </div>
      )}
    </div>
  );
};

export default LiveTiming;
