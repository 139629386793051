import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/style/raceentries.css";
import ErrorPage from "../ErrorPage";
import CircuitDataWeather from "../../components/Championship/CircuitDataWeather";
import LoadingContent from "../../components/LoadingContent";
import REntriesSecondary from "./parts/REntriesSecondary";
import REntriesTeams from "./parts/REntriesTeams";
import { useParams } from "react-router-dom";
import { sortAssignments } from "../../utils/General";

const RaceEntries = ({
  setUpdateNotifications,
}: {
  setUpdateNotifications: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  let { champ } = useParams();
  document.title = "LVF1 - " + t("RaceEntries");
  const [update, setUpdate] = useState(true);
  const [error, setError] = useState(false);
  const [typeChamp, setTypeChamp] = useState(0);
  const [typeRaces, setTypeRaces] = useState(0);
  const [dates, setDates] = useState([]);
  const [datesO, setDatesO] = useState([]);
  const [raceData, setRaceData] = useState<RaceEntries>();

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/getEntryListData`,
        {
          params: {
            champ,
          },
        }
      )
      .then(({ data }) => {
        const type_champ = data.nextgp.type_championship;
        setTypeChamp(type_champ);
        const type_races = data.nextgp.type_races;
        setTypeRaces(type_races);
        setDates(
          type_champ === 1
            ? data.config.main_race
            : type_champ === 3
            ? data.config.secondary_race
            : data.config.event_race
        );
        setDatesO(
          type_champ === 1
            ? data.config_o.main_race
            : type_champ === 3
            ? data.config_o.secondary_race
            : data.config_o.event_race
        );
        if (data.drivers != null) sortAssignments(data.drivers);
        setRaceData(data);
      })
      .catch(({ response }) => {
        setError(true);
        throw new Error(response);
      });
  }, [update, champ]);

  if (error) return <ErrorPage />;
  if (raceData == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div
        id="race-entries-container"
        className={`no-second-padding frame-turqoise ${
          raceData.drivers_sec == null && "higher-racentry"
        }`}
      >
        <h3 className="text-center frame-title">
          {t("RaceEntries").toUpperCase()}
        </h3>
        <div className="flex-center full-width align-top">
          <CircuitDataWeather
            typeRaces={typeRaces}
            circuitData={{
              flag_code: raceData.nextgp.flag_code,
              flag_name: raceData.nextgp.flag_name,
              gp_name: raceData.nextgp.gp_name,
              circuit_id: raceData.nextgp.circuit_id,
              gp_race_event_id: raceData.nextgp.gp_race_event_id,
              circuit_name: raceData.nextgp.circuit_name,
              lat: raceData.nextgp.lat,
              lon: raceData.nextgp.lon,
              race_data: raceData.race_laps,
              dates: dates,
            }}
          />
          <div id="race-entries-right">
            {raceData.drivers_sec == null ? (
              <REntriesTeams
                raceData={raceData}
                setUpdateNotifications={setUpdateNotifications}
                setUpdate={setUpdate}
                update={update}
                dates={dates}
                datesO={datesO}
                typeChamp={typeChamp}
              />
            ) : (
              <REntriesSecondary
                raceData={raceData}
                setUpdateNotifications={setUpdateNotifications}
                setUpdate={setUpdate}
                update={update}
                dates={dates}
                datesO={datesO}
                typeChamp={typeChamp}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaceEntries;
