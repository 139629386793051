import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Lap } from "./GrandPrix.types";
import { animated, useTransition } from "react-spring";

const GrandPrixLaps = ({
  laps,
  showDetails,
}: {
  laps: Lap[];
  showDetails: boolean;
}) => {
  const { t } = useTranslation();
  const [valid, setValid] = useState(false);
  useEffect(() => {
    setValid(showDetails);
  }, [showDetails]);
  const transition = useTransition(valid, {
    from: { height: 0, opacity: 0 },
    enter: {
      height: laps != null && laps.length > 0 ? laps.length * 21.5 + 27 : 0, //laps*lap_height + header
      opacity: 1,
    },
    leave: { height: 0, opacity: 1 },
  });

  const hasTiresData = (laps: Lap[]) => {
    return laps.reduce((previous, lap) => {
      if (lap.tyreWear.fl.value != null) return true;
      return previous;
    }, false);
  };

  const hasFuelData = (laps: Lap[]) => {
    return laps.reduce((previous, lap) => {
      if (lap.fuel.value != null) return true;
      return previous;
    }, false);
  };

  if (laps == null || laps.length == 0) return <></>;
  return transition((style, item) => {
    return (
      item && (
        <animated.div style={style} className="gp-driver-laps">
          <div className="gp-driver-laps-header flex text-center">
            <div className="gp-driver-laps-pos" title={t("Position")}>
              #
            </div>
            {laps[0].driver_name != "" && (
              <div className="gp-driver-laps-lap">{t("Driver")}</div>
            )}
            <div className="gp-driver-laps-lap">{t("Lap")}</div>
            <div className="gp-driver-laps-time">{t("Time")}</div>
            <div className="gp-driver-laps-gap">{t("Gap")}</div>
            <div className="gp-driver-laps-sector">{t("Sector")} 1</div>
            <div className="gp-driver-laps-sector">{t("Sector")} 2</div>
            <div className="gp-driver-laps-sector">{t("Sector")} 3</div>
            {hasTiresData(laps) && (
              <div className="gp-driver-laps-tyres">{t("Tyres")}</div>
            )}
            {hasFuelData(laps) && (
              <div className="gp-driver-laps-fuel">{t("Fuel")}</div>
            )}
          </div>
          {laps.map((lap: Lap, index: number) => {
            return (
              <div
                key={lap.num}
                style={{
                  color: laps[index].color,
                }}
                className="gp-driver-laps-times flex text-center"
              >
                <div className="gp-driver-laps-pos">
                  <label>{laps[index].pos}</label>
                </div>

                {laps[0].driver_name != "" && (
                  <div className="gp-driver-laps-lap">
                    {laps[index].driver_name}
                  </div>
                )}
                <div className="gp-driver-laps-lap">
                  <label>{`${t("Lap")} ${laps[index].num}`}</label>
                </div>
                <div className="gp-driver-laps-time">
                  <label>{laps[index].time}</label>
                </div>
                <div className="gp-driver-laps-gap">
                  <label>{laps[index].gap}</label>
                </div>
                <div className="gp-driver-laps-sector">
                  <label>{laps[index].sectors.s1}</label>
                </div>
                <div className="gp-driver-laps-sector">
                  <label>{laps[index].sectors.s2}</label>
                </div>
                <div className="gp-driver-laps-sector">
                  <label>{laps[index].sectors.s3}</label>
                </div>
                {hasTiresData(laps) && (
                  <div className="gp-driver-laps-tyres">
                    {laps[index].tyreWear.rl.value != null && (
                      <div className="wear-container flex-center">
                        <img
                          src={require(`../../assets/Images/TyresCar.png`)}
                          alt={t("Car")}
                        />
                        <span
                          className="gp-tyre-rl"
                          style={{ color: laps[index].tyreWear.rl.color }}
                        >
                          {laps[index].tyreWear.rl.value}
                        </span>
                        <span
                          className="gp-tyre-rr"
                          style={{ color: laps[index].tyreWear.rr.color }}
                        >
                          {laps[index].tyreWear.rr.value}
                        </span>
                        <span
                          className="gp-tyre-fl"
                          style={{ color: laps[index].tyreWear.fl.color }}
                        >
                          {laps[index].tyreWear.fl.value}
                        </span>
                        <span
                          className="gp-tyre-fr"
                          style={{ color: laps[index].tyreWear.fr.color }}
                        >
                          {laps[index].tyreWear.fr.value}
                        </span>
                        {laps[index].compounds.rear != null && (
                          <img
                            title={laps[index].compounds.rear.toUpperCase()}
                            className="tyre-image rear-compound"
                            src={`/assets/Tyres/${laps[index].compounds.rear}.png`}
                          />
                        )}
                        {laps[index].compounds.front != null && (
                          <img
                            title={laps[index].compounds.front.toUpperCase()}
                            className="tyre-image front-compound"
                            src={`/assets/Tyres/${laps[index].compounds.front}.png`}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
                {hasFuelData(laps) && (
                  <div className="gp-driver-laps-fuel flex-center">
                    {laps[index].fuel.value != "NaN%" && (
                      <>
                        <div
                          className="bar-fuel"
                          style={{
                            background: `linear-gradient(0deg, ${laps[index].fuel.color} 66%, rgb(0 0 0)100%)`,
                            width: laps[index].fuel.value,
                          }}
                        ></div>
                        <label>{laps[index].fuel.value}</label>
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </animated.div>
      )
    );
  });
};

export default GrandPrixLaps;
