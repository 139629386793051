import "../../../assets/style/calendar.css";
import "react-calendar/dist/Calendar.css";
import React, { useEffect, useState, useCallback } from "react";
import Calendar from "react-calendar";
import axios from "axios";
import $ from "jquery";
import { i18n } from "../../../App";
import { useTranslation } from "react-i18next";

const CalendarContent = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState<
    { name: string; date: Date; type_championship: number }[]
  >([]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/main/calendar`
      )
      .then(({ data }) => {
        setEvents(data);
        jQuery();
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, []);

  const jQuery = () => {
    $(".react-calendar__tile").on("mouseout", function (e) {
      e.preventDefault();
      $(".titleContainer").css("opacity", 0);
    });
    $(".react-calendar__tile").on("mouseover", function (e) {
      e.preventDefault();
      $(this).find(".titleContainer").css("opacity", 1);
    });
  };
  $(".react-calendar").on("DOMSubtreeModified", function () {
    jQuery();
  });

  const findDay = (date: Date, type: number) => {
    return events.find((x) => {
      return (
        date.getFullYear() === new Date(x.date).getFullYear() &&
        date.getMonth() === new Date(x.date).getMonth() &&
        date.getDate() === new Date(x.date).getDate() &&
        x.type_championship === type
      );
    });
  };

  const setClass = useCallback(
    (date: any) => {
      const dateobj_type1 = findDay(date, 1);
      const dateobj_type2 = findDay(date, 3);
      const dateobj_type3 = findDay(date, 4);
      if (dateobj_type1 != null) return "day-selected";
      else if (dateobj_type2 != null) return "day-selected-sec";
      else if (dateobj_type3 != null) return "day-selected-eve";
      return "";
    },
    [events]
  );

  const setTitle = useCallback(
    (date: any, view: any) => {
      const dateobj = events.find((x) => {
        if (
          date.getDate() === new Date(x.date).getDate() &&
          date.getMonth() === new Date(x.date).getMonth() &&
          date.getFullYear() === new Date(x.date).getFullYear()
        )
          return true;
        return false;
      });
      return dateobj ? (
        <div className="titleContainer flex-center square-bubble">
          <span className="spanTitle">{dateobj.name}</span>
        </div>
      ) : null;
    },
    [events]
  );
  return (
    <div id="calendar-div" className="column-around">
      <div>
        <Calendar
          tileClassName={({ date }) => setClass(date)}
          tileContent={({ date, view }) => setTitle(date, view)}
          locale={i18n.language}
        ></Calendar>
      </div>
    </div>
  );
};

export default CalendarContent;
