import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import GrandPrixDriver from "../../../components/GrandPrix/GrandPrixDriver";
import GrandPrixTeam from "../../../components/GrandPrix/GrandPrixTeam";
import GrandPrixPosition from "../../../components/GrandPrix/GrandPrixPosition";
import GrandPrixTyre from "../../../components/GrandPrix/GrandPrixTyre";
import GrandPrixSectors from "../../../components/GrandPrix/GrandPrixSectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
} from "@fortawesome/free-solid-svg-icons";
import GrandPrixLaps from "../GrandPrixLaps";
import { DriverSession } from "./Qualifying.types";
import { Lap } from "../GrandPrix.types";

const QualifyingRow = ({
  driver,
  testLap,
  testDriver,
  activeDriver,
  setActiveDriver,
  session,
  index,
  championship,
  total,
  hasTeams,
}: {
  driver: DriverSession;
  testLap: Lap;
  testDriver: DriverSession;
  activeDriver: { [key: string]: number };
  setActiveDriver: any;
  session: string;
  index: number;
  championship: number;
  total: number;
  hasTeams: number;
}) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const showDriverLaps = (th: any) => {
    setActiveDriver({ ...activeDriver, [session]: !showDetails ? index : -1 });
    setShowDetails(!showDetails);
  };
  useEffect(() => {
    if (activeDriver[session] !== index) setShowDetails(false);
  }, [activeDriver[session]]);
  return (
    <div
      className={`gp-driver-laps-container ${
        driver.team === "LigaVirtualF1 Team" ? "lvf1-driver" : ""
      } ${
        activeDriver.hasOwnProperty(session) &&
        activeDriver[session] !== -1 &&
        activeDriver[session] !== index &&
        !showDetails &&
        "low-opacity"
      }`}
    >
      <div className="gp-driver-row flex">
        <GrandPrixPosition
          driver={driver}
          session={session}
          championship={championship}
          total={total}
        />
        <GrandPrixDriver driver={[driver]} />
        {hasTeams === 1 && <GrandPrixTeam driver={driver} />}
        {driver.class != null && driver.class_color != null && (
          <div className="gp-sector text-center">
            <label
              className="class_content"
              style={{ background: driver.class_color }}
            >
              {driver.class.toUpperCase()}
            </label>
          </div>
        )}
        <div className="gp-hotlap text-center">
          <label>
            {testLap != null &&
              driver.fastestLap != null &&
              driver.laps[driver.fastestLap].time}
            {testLap == null && driver.fastestLap}
          </label>
        </div>
        <div className="gp-gap text-center">
          <label>{driver.gap}</label>
        </div>
        {testLap != null && testLap.hasOwnProperty("sectors") && (
          <GrandPrixSectors
            sectors={
              driver.fastestLap != null
                ? driver.laps[driver.fastestLap].sectors
                : null
            }
          />
        )}
        {testLap != null &&
          testLap.hasOwnProperty("compounds") &&
          testLap.compounds.front !== null && (
            <div className="gp-tyre text-center" title={t("Tyres")}>
              {driver.fastestLap != null && (
                <GrandPrixTyre
                  tyre={driver.laps[driver.fastestLap].compounds.front}
                />
              )}
            </div>
          )}
        {testDriver.hasOwnProperty("points") && testDriver.points != null && (
          <div className="gp-points text-center">
            <label>{driver.points != null ? driver.points : ""}</label>
          </div>
        )}
        {testLap != null && driver.laps !== null && (
          <div className="gp-detail text-center">
            <FontAwesomeIcon
              icon={
                !showDetails ? faMagnifyingGlassPlus : faMagnifyingGlassMinus
              }
              onClick={() => showDriverLaps(this)}
            />
          </div>
        )}
      </div>

      <GrandPrixLaps laps={driver.laps} showDetails={showDetails} />
    </div>
  );
};

export default QualifyingRow;
