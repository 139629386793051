import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/umd/Page/AnnotationLayer.css";
import { i18n } from "../App";
import "../assets/style/regulation.css";
import { useParams } from "react-router-dom";
import axios from "axios";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Regulation = () => {
  let { champ } = useParams();
  const { t } = useTranslation();
  document.title = "LVF1 - " + t("Regulation");
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomLeft, setZoomLeft] = useState(1);
  const [zoomRight, setZoomRight] = useState(1);
  const [typeChamp, setTypeChamp] = useState(1);
  const draggableLeft = useRef<any>(null);
  const draggableRight = useRef<any>(null);
  const container = useRef<any>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/getTypeChamp`,
        {
          params: {
            champ,
          },
        }
      )
      .then(({ data }) => {
        setTypeChamp(data.type);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [champ]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setContainerWidth(container.current.offsetWidth);
  };

  const getVHHeight = () => {
    var h = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    return (90 * h) / 100 - 30;
  };

  const changeZoomLeft = (type: number) => {
    if (type) setZoomLeft(zoomLeft + 0.5);
    else if (zoomLeft > 1) setZoomLeft(zoomLeft - 0.5);
    if (draggableLeft != null && draggableLeft.current != null) {
      draggableLeft.current.state.x = 0;
      draggableLeft.current.state.y = 0;
    }
  };

  const changeZoomRight = (type: number) => {
    if (type) setZoomRight(zoomRight + 0.5);
    else if (zoomRight > 1) setZoomRight(zoomRight - 0.5);
    if (draggableRight != null && draggableRight.current != null) {
      draggableRight.current.state.x = 0;
      draggableRight.current.state.y = 0;
    }
  };

  const changePage = (type: number) => {
    const offset = containerWidth > 1060 ? 2 : 1;

    if (type === 1 && pageNumber === 1) setPageNumber(2);
    else if (type === 1) setPageNumber(pageNumber + offset);
    else if (type === 0 && pageNumber === 2) setPageNumber(1);
    else setPageNumber(pageNumber - offset);
  };
  return (
    <div
      ref={container}
      className="full-height padding-md app-container flex-center-top page-transition"
    >
      <div id="regulation" className="flex-center frame-turqoise">
        <h3 className="text-center frame-title">
          {t("Regulation").toUpperCase()}
        </h3>
        <Document
          file={`/assets/Regulation/${typeChamp}/Regulation_${
            i18n.language === "es" ? "ES" : "EN"
          }.pdf?dummy=${Math.floor(Math.random() * 1001)}`}
          /*onLoadError={console.error}*/
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Draggable ref={draggableLeft} disabled={zoomLeft === 1}>
            <div className={zoomLeft > 1 ? "zoomed" : ""}>
              <Page
                height={getVHHeight()}
                pageNumber={pageNumber}
                scale={zoomLeft}
              />
            </div>
          </Draggable>
          <div className="tools">
            <span onClick={() => changeZoomLeft(1)}>+</span>
            <span onClick={() => changeZoomLeft(0)}>-</span>
          </div>
        </Document>

        {pageNumber > 1 &&
          numPages >= pageNumber + 1 &&
          containerWidth > 1060 && (
            <Document
              file={`/assets/Regulation/${typeChamp}/Regulation_${
                i18n.language === "es" ? "ES" : "EN"
              }.pdf?dummy=${Math.floor(Math.random() * 1001)}`}
              /*onLoadError={console.error}*/
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Draggable ref={draggableRight} disabled={zoomRight === 1}>
                <div className={zoomRight > 1 ? "zoomed" : ""}>
                  <Page
                    height={getVHHeight()}
                    pageNumber={pageNumber + 1}
                    scale={zoomRight}
                  />
                </div>
              </Draggable>
              <div className="tools">
                <span onClick={() => changeZoomRight(1)}>+</span>
                <span onClick={() => changeZoomRight(0)}>-</span>
              </div>
              <div className="fold"></div>
            </Document>
          )}
        {pageNumber > 1 && (
          <span id="prev-page" onClick={() => changePage(0)}>
            {"<"}
          </span>
        )}
        {((containerWidth >= 1060 && pageNumber < numPages - 1) ||
          (containerWidth < 1060 && pageNumber < numPages)) && (
          <span id="next-page" onClick={() => changePage(1)}>
            {">"}
          </span>
        )}
      </div>
    </div>
  );
};

export default Regulation;
