import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../assets/style/championship.css";
import { useTranslation } from "react-i18next";
import { DriverLink, TeamLink } from "../../components/FlagLink";
import LoadingContent from "../../components/LoadingContent";
import Flag from "../../components/Flag";
import { useParams } from "react-router-dom";
import { sortAssignments } from "../../utils/General";
import { teamLogo } from "../../utils/Standings";

interface Assignment {
  team_number: number;
  team_championship_id: number;
  assignment_id: number;
  number: number;
  username: string;
  main_color: string;
  secondary_color: string;
  team_name: string;
  engine: string;
  team_flag: string;
  driver_flag: string;
  team_flag_name: string;
  driver_flag_name: string;
  class_name: string;
}
const Drivers = () => {
  const { t } = useTranslation();
  let { champ } = useParams();
  document.title = "LVF1 - " + t("Drivers");
  const [assignments, setAssignments] = useState<
    {
      team_championship_id: number;
      drivers: Assignment[];
    }[]
  >([]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/driver/getTitularDrivers`,
        {
          params: {
            champ_name: champ,
          },
        }
      )
      .then(({ data }) => {
        sortAssignments(data);
        setAssignments(data);
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [champ]);

  if (Object.keys(assignments).length == 0) return <LoadingContent />;
  //añadir en pilotos titulares cuando se cree un champ si no es externo
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div id="team-drivers-container">
        <table id="team-drivers-table" className="form-container-md">
          <thead className="text-center">
            <tr>
              <th>{t("Team")}</th>
              <th>{t("Logo")}</th>
              {assignments[0].drivers[0].class_name != null && (
                <th>{t("Class")}</th>
              )}
              <th>{t("Car")}</th>
              <th>{t("Drivers")}</th>
            </tr>
          </thead>
          <tbody>
            {assignments != null &&
              assignments.map((team: any, index) => {
                console.log(team);
                return (
                  <tr key={index}>
                    <td>
                      <b>
                        <TeamLink
                          flag_code={team.drivers[0].team_flag}
                          flag_name={team.drivers[0].team_flag_name}
                          name={team.drivers[0].team_name}
                          nickname={team.drivers[0].team_name}
                        />
                      </b>
                    </td>
                    <td className="text-center">
                      <div className="team-container">
                        {teamLogo(
                          team.drivers[0].team_id,
                          team.drivers[0].team_logo,
                          team.drivers[0].team_name
                        )}
                      </div>
                    </td>
                    {assignments[0].drivers[0].class_name != null && (
                      <td className="text-center">
                        <label
                          className="class-label"
                          style={{ background: team.drivers[0].class_color }}
                        >
                          {team.drivers[0].class_name}
                        </label>
                      </td>
                    )}
                    <td className="team-image-td">
                      <img
                        alt={team.drivers[0].team_name}
                        title={team.drivers[0].team_name}
                        src={`/assets/TeamChampionship/${
                          team.team_championship_id
                        }.png?dummy=${Math.floor(Math.random() * 1001)}`}
                      />
                    </td>
                    <td>
                      {team.drivers.map((driver: any, index: number) => {
                        return (
                          <div className="flex margin-5" key={index}>
                            <b
                              className="driver-number"
                              style={{
                                background: driver.main_color,
                                color: driver.secondary_color,
                              }}
                            >
                              {driver.number == null ? "#" : driver.number}
                            </b>

                            <b>
                              {driver.username == null ? (
                                <>
                                  <Flag flag_code={null} name={null} />
                                  TBA
                                </>
                              ) : (
                                <DriverLink
                                  flag_code={driver.driver_flag}
                                  flag_name={driver.driver_flag_name}
                                  name={driver.username}
                                  nickname={driver.username}
                                />
                              )}
                            </b>
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Drivers;
